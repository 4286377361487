/* Base gradient button styling */
.gradient-button-admin {
  border: none !important;
  color: #fff !important;
  font-weight: 500 !important;
  border-radius: 4px !important;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15) !important;
  transition: all 0.3s ease !important;

  /* Ensures consistent width for all gradient buttons */
  min-width: 160px;
  text-align: center;
}

/* Margin between each button can be handled with gap in the parent
   or add a margin rule here. For instance:
.gradient-button:not(:last-child) {
  margin-right: 8px;
}
*/

/* Export button: teal/blue gradient */
.export-button-admin {
  background: linear-gradient(to right, #36d1dc, #5b86e5) !important;
}
.export-button-admin:hover {
  filter: brightness(0.95) !important;
}

/* Update Subscription button: green/blue gradient */
.update-sub-button-admin {
  background: linear-gradient(to right, #5a9ee8, #528dcc) !important;
}
.update-sub-button-admin:hover {
  filter: brightness(0.95) !important;
}

/* Delete button: red/orange gradient */
.delete-button-admin {
  background: linear-gradient(to right, #ff416c, #ff4b2b) !important;
}
.delete-button-admin:hover {
  filter: brightness(0.95) !important;
}
